<template>
  <div class="">    


    <UiTabsSimple 
      :tabs="TabsDiaryHar" 
      :acttab="activeTab"
      @choose="activeTab = $event"
      />    
    
    <!-- <WeekwideViewActionsEdit 
      :is-harvest="true"
      :weekid="week.id" 
      :owner="diary.item_user.id" 
      :diarylink="diary.link" 
      @remove="emits('removeweek')"
      />       -->

    <WeekwideViewHarvestSeedReview 
      v-show="activeTab == 'reviews'"
      :data="week" 
      /> 


    <WeekwideViewHarvestConditions 
      v-show="activeTab == 'setup'"
      :data="week" 
      /> 

    <WeekwideViewHarvestConditionsCalc 
      v-show="activeTab == 'setup'"
      :weight="week.props.harvest_weight" 
      :wetweight="week.props.harvest_wet_weight" 
      :plant="week.props.harvest_plant" 
      :watt="week.props.harvest_watt" 
      :space="week.props.harvest_space" 
      /> 
 
  
    <WeekwideViewTastes 
      v-show="activeTab == 'reviews'"
      :taste="week.item_harvest.item_review_seed.items_taste"   
      />
    
    <WeekwideViewEffects 
      v-show="activeTab == 'reviews'"
      :positive="week.item_harvest.item_review_seed.items_effect_positive" 
      :negative="week.item_harvest.item_review_seed.items_effect_negative" 
      :medical="week.item_harvest.item_review_seed.items_effect_medical" 
      :indica="week.item_harvest.item_review_seed.props.indica" 
      />


    <WeekwideViewHarvestNutrientReview 
      v-show="activeTab == 'reviews'"
      :data="week.item_harvest.items_nutrient"
      />

 
    <!-- <WeekwideViewComment 
      :week="week" 
      :user="diary.item_user"
      /> -->

      <CommentWidget
      v-show="activeTab == 'comments' && week.id"
      :type="'diary'"
      :content="week.id"
      />


  </div>
</template>

<script setup>

import { TabsDiaryHar } from "~/types/Enums"
const props = defineProps(['week', 'diary']);

const emits = defineEmits(['removeweek']);
const route = useRoute();
const link = ref(route.params['week']);
const activeTab = ref('setup');
const query = ref('');
const data = ref({});

const removeWeek = () => {
  emits('removeweek');
}
 

</script>

<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.flow_boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

</style>
