<template>
  <div class="">    



    <UiTabsSimple 
      :tabs="TabsDiary" 
      :acttab="activeTab"
      @choose="activeTab = $event"
      />    
  
    <!-- <WeekwideViewActionsEdit :weekid="week.id" :owner="diary.item_user.id" :diarylink="diary.link" @remove="removeWeek"/>       -->

    
    <WeekwideViewConditions 
      v-show="activeTab == 'setup'"
      :days="week.days"
      :faza="week.faza"
      :props="week.props"
      :data="week.item_vegetation" />

    <WeekwideViewNutrients 
      v-show="activeTab == 'nutrients'"
      :nutrients="week.item_vegetation.items_nutrient" />

    <WeekwideViewMethods 
      v-show="activeTab == 'setup'"
      :items-method="week.item_vegetation.items_method" />


    <!-- <WeekwideViewComment :week="week" :user="diary.item_user"/> -->


    <CommentWidget
      v-show="activeTab == 'comments' && week.id"
      :type="'diary'"
      :content="week.id"
      />

  </div>
</template>

<script setup>

import { TabsDiary } from "~/types/Enums"
const props = defineProps(['week', 'diary']);

const emits = defineEmits(['removeweek']);
const route = useRoute();
const link = ref(route.params['week']);
const activeTab = ref('setup');
const query = ref('');
const data = ref({});

const removeWeek = () => {
  emits('removeweek');
}
 

</script>

<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.flow_boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

</style>
